<template>
	<div>
		<v-card v-bind="customCard">
			<v-toolbar class="rounded-t" flat>
				<v-toolbar-title class="d-flex align-center">
					Documents
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn @click="" color="primary">
					<v-icon>mdi-content-save</v-icon>
				</v-btn>
			</v-toolbar>
		</v-card>
	</div>
</template>

<script>
export default {
	name: 'ProductDocuments'
}
</script>

<style scoped>

</style>
