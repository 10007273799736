<template>
	<div>
		<page-bar id="page-title" name="Edit Product">
			<template #actions>
				<v-btn @click="showConfirmClose = true" text>
					Close
				</v-btn>
			</template>
		</page-bar>

		<v-container fluid>

			<v-row class="mx-md-2">

				<v-col cols="12" lg="3" md="4">
					<v-card v-bind="customCard" class="mb-2">
						<v-toolbar class="rounded-t" flat>
							<v-toolbar-title class="d-flex align-center">
								Details
							</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-btn @click="" color="primary">
								<v-icon>mdi-content-save</v-icon>
							</v-btn>
						</v-toolbar>

						<v-container fluid>

							<v-row dense>
								<v-col cols="auto">
									<v-checkbox v-model="editing.active" class="ma-0 pa-0" hide-details
												label="Active"></v-checkbox>
								</v-col>
								<v-col cols="auto">
									<v-checkbox v-model="editing.detailedProduct" class="ma-0 pa-0" hide-details
												label="Detailed Product"></v-checkbox>
								</v-col>
								<v-spacer></v-spacer>
							</v-row>


							<v-row dense>

								<v-col cols="12" md="6">
									<v-text-field v-model="editing.name" :id="newUUID()" dense hide-details label="Name"
												  outlined></v-text-field>
								</v-col>

								<v-col cols="12" md="6">
									<v-text-field v-model="editing.sku" :id="newUUID()" dense hide-details label="SKU"
												  outlined></v-text-field>
								</v-col>

								<v-col cols="12">
									<v-textarea v-model="editing.description" :id="newUUID()" auto-grow dense
												hide-details label="Description" outlined rows="3"></v-textarea>
								</v-col>

								<v-col cols="12">
									<v-select v-model="editing.categoryId" :items="categories" dense hide-details
											  item-text="name" item-value="id" label="Category" outlined></v-select>
								</v-col>

								<v-col cols="12">
									<v-text-field v-model="tag" append-outer-icon="mdi-plus" autocomplete="off" dense
												  hide-details label="Add Tag" outlined spellcheck="false"
												  @click:append-outer="addTag" @keydown.enter="addTag"></v-text-field>
								</v-col>
								<v-col cols="12">
									<template v-for="t in editing.tags">
										<v-chip class="ma-1" close @click:close="removeTag(t)">
											{{ t }}
										</v-chip>
									</template>
								</v-col>
							</v-row>

							<v-row v-if="editing.detailedProduct" dense>
								<v-col class="mt-4" cols="12">
									<div class="d-flex flex-row align-center">
											<span @click="expandSpecifications = !expandSpecifications"
												  class="text--primary title clickable-text">Specifications</span>
										<v-divider class="mx-2"></v-divider>
										<v-fade-transition mode="out-in">
											<v-icon v-if="expandSpecifications" @click="expandSpecifications = false">
												mdi-chevron-up
											</v-icon>
											<v-icon v-else @click="expandSpecifications = true">mdi-chevron-down
											</v-icon>
										</v-fade-transition>
									</div>
								</v-col>

								<v-col cols="12">
									<v-expand-transition>
										<v-row v-if="expandSpecifications" dense>
											<v-col v-for="(_, name) in editing.specifications" :key="name" cols="12">
												<v-textarea v-model="editing.specifications[name]" :label="name"
															append-outer-icon="mdi-delete" auto-grow dense hide-details
															outlined rows="1"
															@click:append-outer="removeSpecification(name)"></v-textarea>
											</v-col>
											<v-col cols="12">
												<div class="d-flex flex-row align-center">
													<v-text-field v-model="specification" autocomplete="off"
																  class="mr-2" dense hide-details
																  label="Specification Name" outlined spellcheck="false"
																  @keydown.enter="addSpecification"></v-text-field>
													<v-btn @click="addSpecification" color="primary" text>Add
														Specification
													</v-btn>
												</div>
											</v-col>
										</v-row>
									</v-expand-transition>
								</v-col>
							</v-row>

						</v-container>
					</v-card>

				</v-col>

				<v-col cols="12" lg="9" md="8">
					<div class="d-flex flex-column">
						<product-images class="mb-2"></product-images>
						<product-documents></product-documents>
					</div>
				</v-col>

			</v-row>

		</v-container>

		<card-dialog :showing="showConfirmClose" @cancel="showConfirmClose = false" @confirmed="confirmClose"
					 title="Confirm Close">
			<template #dialog-body>
				<v-row dense>
					<v-col cols="12">
						Are you sure you want to close this window?
					</v-col>
				</v-row>
			</template>
		</card-dialog>
	</div>
</template>

<script>
import PageBar from '@/components/global/PageBar'
import {mapActions, mapState} from 'vuex'
import CardDialog from '@/components/global/CardDialog'
import ProductImages from '@/components/accessory/ProductImages'
import ProductDocuments from '@/components/accessory/ProductDocuments'

export default {
	name: 'EditProduct',
	components: {ProductDocuments, ProductImages, CardDialog, PageBar},
	data: () => ({
		editing: {
			name: '',
			sku: '',
			description: '',
			categoryId: 0,
			tags: [],
			specifications: {},
			active: false,
			detailedProduct: false,
		},
		tag: '',
		specification: '',
		expandSpecifications: true,
		showConfirmClose: false,
	}),
	created() {
		this.editing = {...this.product}
		this.listAllCategories()
	},
	mounted() {
		this.scrollToElement('page-title')
	},
	methods: {
		...mapActions('accessory/category', [
			'listAllCategories',
		]),
		addTag() {
			this.tag = this.tag.trim()
			if (!this.tag) return
			const idx = this.editing.tags.indexOf(this.tag)
			if (idx === -1) {
				this.editing.tags.push(this.tag)
			}
			this.tag = ''
		},
		removeTag(tag) {
			const idx = this.editing.tags.indexOf(tag)
			if (idx !== -1) {
				this.editing.tags.splice(idx, 1)
			}
		},
		addSpecification() {
			this.specification = this.specification.trim()
			if (this.editing.specifications.hasOwnProperty(this.specification)) return
			this.editing.specifications[this.specification] = ''
			this.specification = ''
		},
		removeSpecification(name) {
			if (!this.editing.specifications.hasOwnProperty(name)) return
			let tmp = {...this.editing.specifications}
			delete tmp[name]
			this.editing.specifications = {...tmp}
		},
		confirmClose() {
			this.showConfirmClose = false
			this.$router.push({name: 'Products'})
		}
	},
	computed: {
		...mapState('accessory/product', [
			'product'
		]),
		...mapState('accessory/category', [
			'categories'
		])
	}
}
</script>

<style scoped>

</style>
